document.addEventListener("DOMContentLoaded", () => {

    // Load external SVG file
    function loadSVGs() {
        fetch('svgs.html')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.text();
            })
            .then(data => {
                const div = document.createElement('div');
                div.innerHTML = data;
                document.body.insertBefore(div, document.body.childNodes[0]);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    // Load SVGs once the DOM is fully loaded
    loadSVGs();

    // The rest of your script
    const navbarMenu = document.getElementById("menu");
    const burgerMenu = document.getElementById("burger");
    const headerMenu = document.getElementById("header");
    const overlayMenu = document.querySelector(".overlay");

    // Open Close Navbar Menu on Click Burger
    if (burgerMenu && navbarMenu) {
        burgerMenu.addEventListener("click", () => {
            burgerMenu.classList.toggle("is-active");
            navbarMenu.classList.toggle("is-active");
        });
    }

    // Close Navbar Menu on Click Links
    document.querySelectorAll(".menu-link").forEach((link) => {
        link.addEventListener("click", () => {
            burgerMenu.classList.remove("is-active");
            navbarMenu.classList.remove("is-active");
        });
    });

    // Fixed Navbar Menu on Window Resize
    window.addEventListener("resize", () => {
        if (window.innerWidth >= 992) {
            if (navbarMenu.classList.contains("is-active")) {
                navbarMenu.classList.remove("is-active");
                overlayMenu.classList.remove("is-active");
            }
        }
    });

    // Dark and Light Mode on Switch Click
    const darkSwitch = document.getElementById("switch");

    // Function to apply dark mode based on session storage
    function applyDarkModeSetting() {
        const isDarkMode = sessionStorage.getItem("darkMode") === "true"; // Get dark mode setting from session storage
        if (isDarkMode) {
            document.documentElement.classList.add("darkmode");
            document.body.classList.add("darkmode");
        } else {
            document.documentElement.classList.remove("darkmode");
            document.body.classList.remove("darkmode");
        }
    }

    // Event listener for the dark mode switch
    darkSwitch.addEventListener("click", () => {
        const isCurrentlyDark = document.documentElement.classList.contains("darkmode");
        document.documentElement.classList.toggle("darkmode", !isCurrentlyDark);
        document.body.classList.toggle("darkmode", !isCurrentlyDark);
        sessionStorage.setItem("darkMode", !isCurrentlyDark); // Store the current mode in session storage
    });

    // Apply dark mode setting
    applyDarkModeSetting();

    // Progress Bar
    var progressBar = document.querySelector(".progress-bar");

    function updateProgressBar() {
        var scrollPosition = window.scrollY;
        var windowHeight = window.innerHeight;
        var documentHeight = document.documentElement.scrollHeight;
        var progress = scrollPosition / (documentHeight - windowHeight) * 100;
        progressBar.style.width = "".concat(progress, "%");

        if (progress >= 100) {
            progressBar.style.width = "100%";
        }
    }

    window.addEventListener("scroll", updateProgressBar);

    // Highlight Current Page
    const navLinkEls = document.querySelectorAll('.menu-link');
    const windowPathname = window.location.pathname.replace(/\/$/, ''); // Remove trailing slash

    navLinkEls.forEach(navLinkEl => {
        const navLinkPathname = new URL(navLinkEl.href).pathname.replace(/\/$/, ''); // Remove trailing slash

        if (windowPathname === navLinkPathname || (windowPathname === '' && navLinkPathname === '/index.html')) {
            navLinkEl.classList.add('current-page');
        }
    });

    // Email Obfuscator
    const emailLinks = document.querySelectorAll('a[href^="mailto:"]');
    if (emailLinks.length > 0) {
        emailLinks.forEach(link => {
            const href = link.href.replace('mailto:', 'mailto:' + link.innerText);
            link.href = href;
        });
    }

    // Testimonials
    const slider = document.querySelector('.testimonials__slider');
    if (!slider) return; // Exit if no slider found

    const slides = Array.from(slider.children);
    const indicatorImages = document.querySelectorAll('.testimonials__indicator img');
    const storageKey = 'activeTestimonialId';

    function setActiveElement(activeIndex) {
        slides.forEach((slide, index) => {
            slide.classList.remove('active');
            indicatorImages[index].classList.remove('active');
            slide.style.display = 'none'; // Hide all slides
        });
        indicatorImages[activeIndex].classList.add('active');
        slides[activeIndex].classList.add('active');
        slides[activeIndex].style.display = 'block'; // Only display the active slide
        localStorage.setItem(storageKey, activeIndex);
    }

    window.addEventListener('load', () => {
        const storedActiveIndex = localStorage.getItem(storageKey);
        const activeIndex = storedActiveIndex !== null ? parseInt(storedActiveIndex, 10) : 0;
        setActiveElement(activeIndex);
    });

    indicatorImages.forEach((img, index) => {
        img.addEventListener('click', () => {
            setActiveElement(index);
        });
    });
});